<template>
  <div :id="`inputCustom${id}`">
    <div v-if="descr" class="v-input__details">
      <div class="v-messages">
        <div class="v-messages__message" v-html="descr" />
      </div>
    </div>
    <div v-for="(option, idx) in [value]" :key="option.value" class="checkbox-wrapper">
      <v-checkbox
        v-model="selected"
        :rules="rulesCommon"
        :label="`${title} ${required ? '*' : ''}`"
        :value="value"
        :readonly="readOnly"
        :error-messages="errorMessages"
        :disabled="disabled"
        color="primary"
        density="compact"
        @change="setNewValue"
      />
      <v-list-item
        v-if="option.description"
        :class="{
          'checkbox__description--last': options.length - 1 === idx,
        }"
        class="text-caption checkbox__description"
        v-html="option.description"
      />
    </div>
  </div>
</template>

<script setup>
import { props as baseProps, emit as baseEmit } from '@/composables/form/usePropsForm'
import { useForm } from '@/composables/form/useForm'
import { nextTick, onMounted, ref, watch } from 'vue'

const props = defineProps({
  ...baseProps,
})

const selected = ref([])

const emit = defineEmits(baseEmit)

const initData = () => {
  inputModel.value = ''
  selected.value = []
  if (props.default_value) {
    inputModel.value = props.default_value
    selected.value = [`${props.default_value}`]
  } else {
    selected.value = []
    inputModel.value = ''
  }
}

function setNewValue() {
  const val = selected.value?.length ? selected.value[0] : ''
  inputModel.value = val
  emit('change-value', {
    id: props.id,
    default_value: val,
  })
}
const { rulesCommon, inputModel } = useForm(props, emit)

watch(props, () => {
  initData()
})

onMounted(() => {
  nextTick(initData)
})
</script>

<style lang="scss">
.checkbox {
  &__description {
    padding: 0;
    margin-top: -20px;
    margin-left: 40px;
    &--last {
      margin-bottom: 20px;
    }
  }
}
</style>
