<template>
  <div class="footer-container">
    <v-card elevation="2" class="rounded-0">
      <div
        class="content d-flex flex-row justify-space-between flex-md-nowrap flex-md-row flex-column"
      >
        <v-col class="logo pa-0 ma-0 align-start justify-start d-flex mb-6 mb-md-0" cols="3">
          <router-link to="/">
            <SvgBase width="129" height="35">
              <SvgLogo />
            </SvgBase>
          </router-link>
        </v-col>
        <v-col
          class="pa-0 ma-0 d-flex flex-column align-md-start justify-end text-caption text-grey"
          cols="12"
          md="4"
          lg="4"
        >
          <div
            v-for="(link, index) in defaultAgreementLinks"
            :key="index"
            class="text-grey cursor-pointer"
            @click="() => openFile(link)"
          >
            {{ link.title }}
          </div>
          <div class="mt-4">© ФОНД НТИ, {{ getYear() }}</div>
        </v-col>
      </div>
    </v-card>
  </div>
</template>
<script setup>
import { ref } from 'vue'
import SvgBase from '@/components/Svg/SvgBase'
import SvgLogo from '@/components/Svg/SvgLogo'
import { api } from '@/api/Api'

const defaultAgreementLinks = ref([
  {
    title: 'Пользовательское соглашение',
    href: '/Пользовательское соглашение.pdf',
  },
  {
    title: 'Политика конфиденциальности',
    href: '/Политика конфиденциальности.pdf',
  },
  {
    title: 'Инструкция пользователя',
    href: '/Руководство пользователя_ЕЛК.pdf',
  },
])

defineProps({
  tabs: {
    type: Array,
    default: () => [],
  },
})

function getYear() {
  const date = new Date()
  return date.getFullYear()
}

async function openFile(link) {
  let fileLink = link.href
  if (link.isBlobFile) {
    await api
      .upload()
      .getFile(link.href)
      .then((response) => {
        const fileData = URL.createObjectURL(response)
        fileLink = fileData
        window.open(fileLink, '_blank').focus()
      })
  } else {
    window.open(fileLink, '_blank').focus()
  }
}
</script>
<style lang="scss">
.footer-container {
  .content {
    padding: 24px;
  }
}
</style>
