<template>
  <v-dialog v-model="dialog" persistent>
    <v-card width="500">
      <v-card-text>
        <div v-for="group in groups" :key="group?.id">
          <v-row class="align-center justify-start">
            <v-checkbox
              v-model="selectGroup"
              variant="outlined"
              hide-details="auto"
              density="compact"
              :label="group?.name"
              :value="group?.id"
              @change="changeSelectGroups"
            />
            <v-btn
              v-if="group?.description"
              :id="`role-${group?.id}`"
              color="grey"
              icon="mdi-information-outline"
              variant="text"
            />
          </v-row>
          <v-tooltip
            v-if="group?.description"
            :activator="`#role-${group?.id}`"
            max-width="600"
            location="right"
          >
            <div v-html="group?.description" />
          </v-tooltip>
        </div>
      </v-card-text>
      <v-card-actions class="justify-end py-4">
        <v-btn v-if="!isLoading" color="primary" variant="text" @click="close"> Закрыть </v-btn>
        <v-btn :loading="isLoading" variant="elevated" color="primary" @click="addGroup">
          Назначить
        </v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script setup>
import { computed, ref, defineEmits, nextTick } from 'vue'
import { useStore } from 'vuex'
import { api } from '@/api/Api'
import { useToast } from '@/composables/useToast'
import { MONITOR, MONITOR_CONTENT } from '@/enums/roles'

const props = defineProps({
  userId: {
    type: Number,
    default: null,
  },
  userGroups: {
    type: Array,
    default: () => [],
  },
})
console.log(props, '===')

const emit = defineEmits(['on-close'])

const store = useStore()
const { toast } = useToast()

const dialog = ref(false)
const selectGroup = ref([])
const isLoading = ref(false)

const groups = computed(() => store.getters['group/group'])

function changeSelectGroups(e) {
  if ((+e.target.value === MONITOR && e.target.checked) || +e.target.value === MONITOR_CONTENT) {
    selectGroup.value = selectGroup.value?.filter((el) => el === MONITOR || el === MONITOR_CONTENT)
  }

  if (+e.target.value !== MONITOR && +e.target.value !== MONITOR_CONTENT) {
    selectGroup.value = selectGroup.value?.filter((el) => el !== MONITOR && el !== MONITOR_CONTENT)
  }
}

function open() {
  dialog.value = true
  nextTick(() => {
    if (props.userGroups.length !== 0) {
      selectGroup.value = props.userGroups?.map((group) => group.groupId)
    }
  })
}
function close() {
  emit('on-close')
  selectGroup.value = []
  dialog.value = false
}
async function addGroup() {
  const form = new FormData()
  form.append('user_id', props.userId)
  selectGroup.value.forEach((groupId) => {
    form.append('groups[]', groupId)
  })
  isLoading.value = true
  try {
    await api.user().setGroupToUser(form)
    await store.dispatch('user/getUsers')
    toast.success('Действие успешно выполнено')
  } catch (e) {
    toast.error('При назначение произошла ошибка')
  } finally {
    isLoading.value = false
  }
}

defineExpose({
  open,
})
</script>
<script>
export default {
  name: 'UsersAddGroupDialog',
}
</script>
