<template>
  <DataTable
    ref="dt"
    v-model:filters="searchValue"
    :value="items"
    lazy
    paginator
    :rows="20"
    data-key="id"
    :sortable="false"
    :total-records="totalCount"
    @page="handlePageChange"
  >
    <template #empty>
      <v-row justify="center">
        <v-col cols="auto"> Данные не найдены </v-col>
      </v-row>
    </template>
    <template v-if="showSearchFilter" #header>
      <v-row justify="end">
        <v-col md="4" sm="6">
          <v-text-field
            v-model="searchValue['global'].value"
            prepend-inner-icon="mdi-magnify"
            variant="outlined"
            density="compact"
            hide-details="auto"
            placeholder="Поиск по таблице"
            @change="emits('update-search')"
          />
        </v-col>
      </v-row>
    </template>
    <slot />
  </DataTable>
</template>

<script setup>
import DataTable from 'primevue/datatable'
import { ref } from 'vue'
import { FilterMatchMode } from 'primevue/api'

defineProps({
  items: {
    type: Array,
    default: () => [],
  },
  attributes: {
    type: Object,
    default: () => {
      return {}
    },
  },
  showSearchFilter: {
    type: Boolean,
    default: false,
  },
  totalCount: {
    type: Number,
    default: null,
  },
})

const emits = defineEmits(['update-page', 'update-search'])

const searchValue = ref({
  global: { value: null, matchMode: FilterMatchMode.CONTAINS },
})

const handlePageChange = (page) => {
  emits('update-page', page.page + 1)
}
</script>
<style lang="scss">
.p-sortable-column .p-sortable-column-icon {
  display: none;
}
.p-datatable-wrapper .p-datatable-table .p-datatable-thead > tr > th {
  background: none;
}

.page .p-datatable .p-paginator-bottom {
  border: 0;
}
</style>
