<template>
  <div :key="links.length">
    <div class="text-caption mb-2 text-blue-darken-1 font-weight-bold line-height-1 w-100">
      {{ primaryTitle }}
    </div>
    <v-alert v-if="fileExampleId === 19" color="deep-orange-lighten-3" variant="tonal" width="432">
      <v-row class="ma-0 pa-0 align-center">
        <div class="content flex-grow-1 d-flex w-100 flex-column">
          <div class="text-black" v-html="fileType.description" />
        </div>
        <v-row class="pa-0 ma-0 justify-end flex-grow-0 ml-4 cursor-pointer">
          <v-tooltip text="Скачать" location="bottom">
            <template #activator="{ props }">
              <a
                v-if="templateLink"
                v-bind="props"
                :href="templateLink"
                class="text-decoration-none font-weight-black"
              >
                <v-icon color="primary" size="20"> mdi-download </v-icon>
              </a>
            </template>
          </v-tooltip>
        </v-row>
      </v-row>
    </v-alert>
    <div v-else-if="templateFileId > 0 && templateLink">
      <a class="primary-link" :href="templateLink">Скачать шаблон / инструкцию</a>
    </div>
    <div v-else-if="fileExampleId && parcelable">
      <div class="primary-link" @click="() => $emit('download-example', fileExampleId)">
        Скачать шаблон / инструкцию
      </div>
    </div>
    <div v-if="props.fileExampleId !== 19" class="d-flex">
      <v-card class="wrapper-files" border="1" elevation="0">
        <div
          :class="['dropzone-container', isDragging ? 'bg-blue-light' : '']"
          @dragover="dragover"
          @dragleave="dragleave"
          @drop="drop"
        >
          <input
            v-if="showInput"
            :id="id"
            ref="file"
            type="file"
            :multiple="multiple"
            name="file"
            :rules="rulesCommon"
            class="hidden-input"
            :accept="fileTypes?.toLowerCase()"
            @change="onChange"
          />

          <label :for="id" class="file-label">
            <div>
              <div class="title">Загрузите документы</div>
              <div class="description">Или перетяните файлы в эту область</div>
              <div v-if="maxSize" class="description">
                файлы {{ props.fileTypes }} до {{ bytesForHuman(+maxSize) }}
              </div>
            </div>
          </label>
        </div>
      </v-card>
      <template v-if="parcelable">
        <v-icon id="info" color="grey" class="ml-2"> mdi-information-outline </v-icon>
        <v-tooltip activator="#info" location="bottom">
          При загрузке документа система распознает поля автоматически
        </v-tooltip>
      </template>
    </div>
    <div
      v-if="links.length && !fileExampleId !== 19"
      :key="links.length"
      class="preview-container mt-4 w-100 mb-6"
    >
      <div v-for="(file, index) in links" :key="file.id">
        <div class="preview-card">
          <div
            class="d-flex align-center flex-grow-1 cursor-pointer"
            @click="handleDownloadClick(file)"
          >
            <v-tooltip text="Скачать" location="bottom">
              <template #activator="{ props }">
                <v-icon color="primary">{{
                  getFileIcon(
                    Object.values(file)[0].originalFileName ||
                      file.originalFileName ||
                      file.fileName,
                  )
                }}</v-icon>
                <div v-bind="props" class="file-name">
                  {{
                    Object.values(file)[0].originalFileName ||
                    file.originalFileName ||
                    file.fileName
                  }}
                </div>
              </template>
            </v-tooltip>

            <v-tooltip v-if="file.signValid" text="Файл подписан">
              <template #activator="{ props }">
                <v-icon v-bind="props" size="xs" color="grey" class="ml-2">mdi-pen</v-icon>
              </template>
            </v-tooltip>
          </div>
          <v-row class="px-0 mx-0 justify-end flex-grow-0 flex-nowrap align-center">
            <v-tooltip text="Удалить" location="bottom" :activator="`#${file.code1C}`">
              <template #activator="{ props }">
                <v-btn
                  v-bind="props"
                  variant="text"
                  icon="mdi-close"
                  size="sm"
                  @click="() => handleDeleteFile(file, index)"
                />
              </template>
            </v-tooltip>
          </v-row>
        </div>
        <div v-if="file.managerComment" class="text-red text-caption w-100">
          {{ file.managerComment }}
        </div>
      </div>
    </div>
    <div v-if="files.length && defaultInput" class="preview-container mt-4">
      <div v-for="file in files" :key="file.name">
        <div class="preview-card">
          <div class="content">
            <v-icon color="grey">{{ getFileIcon(file.name) }}</v-icon>
            <div :title="file.name" class="file-name">
              {{ file.name }}
            </div>
          </div>
          <div>
            <button class="ml-2" type="button" @click="remove(files.indexOf(file))">
              <v-icon color="red" size="xs"> mdi-delete-outline </v-icon>
              <span class="ml-2 text-body-2 title text-red">Удалить</span>
            </button>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script setup>
import { nextTick, ref, watch } from 'vue'

import { emit as baseEmit, props as baseProps } from '@/composables/form/usePropsForm'
import { useForm } from '@/composables/form/useForm'
import { useToast } from '@/composables/useToast'
import { findMaxNumber, getFileIcon } from '@/helpers'

const { toast } = useToast()

const props = defineProps({
  ...baseProps,
  fileData: {
    type: String,
    default: '',
  },
  primaryTitle: {
    type: String,
    default: '',
  },
  links: {
    type: Array,
    default: () => [],
  },
  defaultInput: {
    type: Boolean,
    default: false,
  },
  maxSize: {
    type: Number,
    default: 20 * 1024 * 1024,
  },
  fileExampleId: {
    type: String,
    default: '',
    required: false,
  },
  fileType: {
    type: Object,
    default: null,
  },
  parcelable: {
    type: Boolean,
    default: false,
  },
  signable: {
    type: Boolean,
    default: false,
  },
  fileTypes: {
    type: String,
    default: '',
  },
  hasError: {
    type: Boolean,
    default: false,
  },
})

const emit = defineEmits(baseEmit)
const files = ref([])
const isDragging = ref(false)
const file = ref(null)
const showInput = ref(true)

const { rulesCommon } = useForm(props, emit)

const handleDeleteFile = (file, index) => {
  files.value = files.value?.filter((el, fileIndex) => index !== fileIndex)
  return emit('delete-file', Object.values(file)[0].id || file.id, file?.attachCode)
}
const handleDownloadClick = (file) => {
  if (file.link) {
    let link = document.createElement('a')
    link.download = file.link
    link.href = file.link
    link.target = '_blank'
    document.body.appendChild(link)
    link.click()
    document.body.removeChild(link)
    return
  }

  emit(
    'download-file',
    Object.values(file)[0].id || file.id,
    Object.values(file)[0].originalFileName || '',
  )
}

function bytesForHuman(bytes, decimals = 2) {
  let units = ['B', 'KB', 'MB', 'GB', 'TB', 'PB']

  let i = 0

  for (i; bytes > 1024; i++) {
    bytes /= 1024
  }

  return parseFloat(bytes?.toFixed(decimals)) + ' ' + units[i]
}

function getFileType(file) {
  const m = file.name.match(/\.([^.]+)$/)
  return m && m[1]
}

function onChange(e) {
  let hasError = false
  if (!props.multiple && (props.links?.length || files.value?.length)) {
    return toast.error(`Данное поле поддерживает загрузку только одного файла`)
  }
  if (props.fileTypes) {
    Object.values(e.target.files)?.forEach((file) => {
      const mimeType = getFileType(file)
      if (!mimeType || !props.fileTypes.includes((mimeType || '').toLowerCase())) {
        e.preventDefault()
        hasError = true
        return toast.error(
          `Неверный формат файла. Небходимо загрузить файл с расширением ${props.fileTypes}`,
        )
      }
    })
  }
  // e.preventDefault()
  console.log(e.target?.files, '===', hasError)
  if (hasError) {
    return
  }
  files.value = [...files.value, ...e.target?.files]
  showInput.value = false
  nextTick(() => {
    showInput.value = true
  })
  const filterSizeFiles = Object.values(e.target.files)?.filter((el) => {
    if (props.maxSize && el.size > props.maxSize) {
      toast.error(`Максимальный размер файла ${bytesForHuman(props.maxSize)}`)
      return null
    }

    return el
  })

  if (!filterSizeFiles?.length) {
    return
  }

  filterSizeFiles?.forEach((file, index) => {
    emit('update-signed-files', {
      value: file,
      id: props.id,
      index: getLastIndexLinks(index) + 1,
    })
  })
}

function remove(i) {
  files.value.splice(i, 1)
  if (document.getElementById(props.id)) {
    document.getElementById(props.id).value = ''
  }
}

function dragover(e) {
  e.preventDefault()
  isDragging.value = true
}

function dragleave() {
  isDragging.value = false
}

function getLastIndexLinks(index = 0) {
  const filterArr = props.links.map((object) => +object.valueIndex)
  return findMaxNumber(filterArr) + index || 0
}

function drop(e) {
  e.preventDefault()
  if (!props.multiple && (props.links?.length || files.value?.length)) {
    return toast.error(`Данное поле поддерживает загрузку только одного файла`)
  }
  Object.values(e.dataTransfer.files)?.forEach((el, index) => {
    if (!props.multiple && index) {
      return toast.error(`Данное поле поддерживает загрузку только одного файла`)
    }
    if (el.size > (props.maxSize || 20000000)) {
      toast.error(`Максимальный размер файла ${bytesForHuman(props.maxSize) || '20МБ'}`)
      return
    }

    const mimeType = getFileType(el)
    const fileType = (mimeType || '').toLowerCase()
    if (!mimeType || (!props.fileTypes.includes(fileType) && props.fileTypes)) {
      return toast.error(`Неверный формат файла ${el.name}`)
    } else {
      files.value = [...files.value, el]
      emit('update-signed-files', {
        value: el,
        id: props.id,
        index: getLastIndexLinks() + index + 1,
      })
    }
  })

  isDragging.value = false
}

watch(props, (value, oldValue) => {
  if (value?.links?.length !== oldValue?.links?.length) {
    files.value = value?.links
  }
  if (props.hasError) {
    files.value = []
  }
})
</script>

<style lang="scss" scoped>
.wrapper-files {
  max-width: 432px;
  width: 100%;
  min-width: 432px;

  @media (max-width: 567px) {
    max-width: 100%;
    min-width: 100%;
  }
}

.dropzone-container {
  border: 1px dashed var(--surface-500);
  border-radius: 4px;
  padding: 16px;

  &.bg-blue-light {
    background: var(--background-thead-table);
    border: 1px solid var(--border-th-table);
  }
}
.title {
  font-size: 14px;
  line-height: 20px;
  text-align: center;
  margin-bottom: 4px;
  font-family: Arial, sans-serif;
}

.description {
  font-size: 12px;
  line-height: 16px;
  color: var(--text-caption);
  margin-bottom: 4px;

  &:last-child {
    margin-bottom: 0;
  }
}
.hidden-input {
  opacity: 0;
  overflow: hidden;
  position: absolute;
  width: 1px;
  height: 1px;
}
.file-label {
  font-size: 20px;
  display: block;
  text-align: center;
  cursor: pointer;
}
.preview-container {
  display: flex;
  flex-direction: column;
  margin-top: 2rem;
  gap: 8px;
}
.preview-card {
  display: flex;
  align-items: center;
  max-width: 432px;
  width: 100%;
  padding: 16px;
  background: var(--bg-gray);
  border-radius: 8px;

  svg,
  i {
    color: var(--primary);
  }
}
.content {
  display: flex;
  flex: 1;
}
.primary-link {
  font-size: 12px;
  line-height: 16px;
  font-weight: 700;
  color: var(--primary);
  text-decoration: none;
  display: flex;
  margin-bottom: 16px;
}

.file-name {
  font-size: 12px;
  line-height: 16px;
  margin-left: 12px;
  display: -webkit-box;
  -webkit-line-clamp: 1;
  -webkit-box-orient: vertical;
  overflow: hidden;
}
</style>
