<template>
  <svg xmlns="http://www.w3.org/2000/svg" width="88" height="82" viewBox="0 0 88 82" fill="none">
    <path
      d="M88 44C88 36.3244 85.9921 28.7823 82.1757 22.1227C78.3593 15.4631 72.8671 9.91768 66.2447 6.03719C59.6222 2.15669 52.0998 0.0761087 44.4245 0.00204823C36.7493 -0.0720123 29.1882 1.86303 22.492 5.61501C15.7959 9.36699 10.1978 14.8054 6.25359 21.3901C2.30938 27.9748 0.156307 35.4767 0.00819272 43.1509C-0.139921 50.8251 1.72207 58.4046 5.40927 65.1366C9.09647 71.8686 14.4806 77.5189 21.0269 81.5265L23.932 76.781C18.2135 73.2802 13.5103 68.3444 10.2893 62.4637C7.06842 56.583 5.44189 49.9621 5.57127 43.2583C5.70066 36.5546 7.58146 30.0013 11.0269 24.2493C14.4723 18.4972 19.3625 13.7466 25.2119 10.4691C31.0612 7.19155 37.6662 5.50121 44.3709 5.56591C51.0755 5.6306 57.6467 7.44808 63.4317 10.8379C69.2167 14.2276 74.0143 19.0718 77.3481 24.8892C80.6819 30.7067 82.4359 37.295 82.4359 44H88Z"
      fill="url(#paint0_linear_4632_14626)"
    />
    <defs>
      <linearGradient
        id="paint0_linear_4632_14626"
        x1="6"
        y1="53"
        x2="15"
        y2="82"
        gradientUnits="userSpaceOnUse"
      >
        <stop stop-color="#F37C4E" />
        <stop offset="1" stop-color="#FF4800" stop-opacity="0" />
      </linearGradient>
    </defs>
  </svg>
</template>
