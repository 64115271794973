<template>
  <div class="page">
    <v-row v-if="isLoading" class="my-12 py-12 w-100 justify-center align-center">
      <v-progress-circular indeterminate color="primary" :size="50" class="ml-4" :width="3" />
    </v-row>
    <div v-else class="pb-0">
      <DataTable
        :value="projects"
        lazy
        data-key="id"
        :sortable="false"
        responsiveLayout="stack"
        @update-page="updatePage"
        @row-click="handleRowClick"
      >
        <Column style="width: 20%" field="name" header="Проект" />
        <Column field="template.name" header="Конкурс" />
        <Column style="width: 200px" field="status.name" header="Статус">
          <template #body="slotProps">
            <div class="chip chip_black">
              {{ slotProps.data.status?.name }}
            </div>
          </template>
        </Column>
        <Column header="">
          <template #body="slotProps">
            <div class="d-flex justify-end align-center cursor-pointer">
              <v-tooltip :activator="`#task-go-${slotProps.data.id}`" location="bottom">
                Перейти
              </v-tooltip>
              <div :id="`task-go-${slotProps.data.id}`" class="link"><ArrowRight /></div>
            </div>
          </template>
        </Column>
      </DataTable>
    </div>
  </div>
</template>
<script setup>
import { api } from '@/api/Api'
import { onMounted, ref } from 'vue'
import { useToast } from '@/composables/useToast'
import store from '@/store'
import Column from 'primevue/column'
import ArrowRight from '@/components/Icons/ArrowRight.vue'
import DataTable from 'primevue/datatable'
import { useRouter } from 'vue-router'

const { toast } = useToast()
const router = useRouter()

const isLoading = ref(false)
const projects = ref([])

const updatePage = () => {}

async function getProjects() {
  isLoading.value = true
  try {
    await api
      .projects()
      .getProjectsList()
      .then((data) => {
        projects.value = data
      })
  } catch (err) {
    toast.error(err.message)
  } finally {
    isLoading.value = false
  }
}

const setPageTitle = () => {
  return store.commit('app/setPageTitle', 'Проекты')
}

const handleRowClick = (event) => {
  router.push(`/projects/${event.data?.id || ''}`)
}

onMounted(() => {
  setPageTitle()
  getProjects()
})
</script>
<style lang="scss" scoped>
.page {
  padding: 24px;
  margin-top: 64px;
  flex: 1;
}
</style>
