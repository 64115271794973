<template>
  <v-row v-if="isLoading" class="my-12 py-12 w-100 justify-center align-center">
    <v-progress-circular indeterminate color="primary" :size="50" class="ml-4" :width="3" />
  </v-row>
  <div v-else class="d-flex flex-column flex-grow-1">
    <div class="form-head">
      <div class="link" @click="() => router.go(-1)">
        <ArrowLeft />
      </div>
      <div class="flex-grow-1">
        <v-row class="pa-0 ma-0 justify-space-between align-center w-100 flex-nowrap">
          <h3 v-if="projectPointData?.name" class="title flex-grow-1">
            {{ projectPointData?.name }}
          </h3>
          <v-row
            v-if="projectPointData?.signValid"
            :key="projectPointData?.signValid"
            class="flex-nowrap flex-grow-0 ma-0 text-grey"
          >
            <span class="mr-2 text-green font-weight-bold">Подписан</span>
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="16"
              height="16"
              viewBox="0 0 24 24"
              fill="none"
            >
              <path
                d="M22 11.08V12C21.9988 14.1564 21.3005 16.2547 20.0093 17.9818C18.7182 19.709 16.9033 20.9725 14.8354 21.5839C12.7674 22.1953 10.5573 22.1219 8.53447 21.3746C6.51168 20.6273 4.78465 19.2461 3.61096 17.4371C2.43727 15.628 1.87979 13.4881 2.02168 11.3363C2.16356 9.18455 2.99721 7.13631 4.39828 5.49706C5.79935 3.85781 7.69279 2.71537 9.79619 2.24013C11.8996 1.7649 14.1003 1.98232 16.07 2.85999"
                stroke="#3F9946"
                stroke-linecap="round"
                stroke-linejoin="round"
              />
              <path
                d="M22 4L12 14.01L9 11.01"
                stroke="#3F9946"
                stroke-linecap="round"
                stroke-linejoin="round"
              />
            </svg>
          </v-row>
        </v-row>
        <div
          v-if="projectPointData?.description"
          class="pt-2 mt-2 descr"
          v-html="projectPointData?.description"
        />
      </div>
    </div>
    <template v-if="isEditedForm && projectPointData?.canEdit">
      <template v-if="projectPointData?.files?.length">
        <div class="task-view">
          <div class="font-weight-bold my-2 files-title">Скачать пакет документов:</div>
          <div
            v-for="file in projectPointData?.files"
            :key="file.fileId"
            class="border-bottom-line task-value align-center flex-column flex-md-row"
          >
            <div class="value-title">{{ file.name }}</div>
            <div class="value d-flex flex-column">
              <div
                class="d-flex justify-space-between align-center file-value-wrapper"
                @click="() => downloadFile(file)"
              >
                <div class="d-flex cursor-pointer align-center">
                  <v-icon size="20" color="primary">{{ getFileIcon(file?.name) }}</v-icon>
                  <v-tooltip text="Скачать" location="bottom">
                    <template #activator="{ props }">
                      <span v-bind="props" class="ml-2 font-weight-medium text-caption">{{
                        file?.name
                      }}</span>
                    </template>
                  </v-tooltip>
                </div>
              </div>
            </div>
          </div>
        </div>
      </template>
      <ProjectForm
        :is-vote="isVoteShowForm"
        :point-data="projectPointData"
        @accept="getProjectPoint"
      />
    </template>
    <TaskPreview
      v-else-if="projectPointData"
      :point-data="projectPointData"
      @update="getProjectPoint"
    />
  </div>
</template>
<script setup>
import { api } from '@/api/Api'
import { useRoute } from 'vue-router/dist/vue-router'
import { computed, onMounted, ref } from 'vue'
import TaskPreview from '@/components/Tasks/TaskPreview'
import { POINT_TYPE_EXPERT, POINTS_STATUSES } from '@/constants/points'
import ProjectForm from '@/components/Project/ProjectForm.vue'
import { downloadFileWithLinkData, getFileIcon } from '@/helpers'
import ArrowLeft from '@/components/Icons/ArrowLeft.vue'
import { useRouter } from 'vue-router'

const route = useRoute()
const router = useRouter()

const projectPointData = ref(null)
const isLoading = ref(false)

const isVoteShowForm = computed(() => projectPointData.value?.type === POINT_TYPE_EXPERT)
const isEditedForm = computed(
  () =>
    projectPointData.value?.status?.code1C === POINTS_STATUSES.NEW ||
    projectPointData.value?.status?.code1C === POINTS_STATUSES.FILLING ||
    projectPointData.value?.status?.code1C === POINTS_STATUSES.NEEDS_IMPROVEMENTS ||
    projectPointData.value?.status?.code1C === POINTS_STATUSES.SUSPENDED,
)

async function getProjectPoint() {
  isLoading.value = true
  try {
    await api
      .projects()
      .getPointById(route.params.id)
      .then((data) => {
        projectPointData.value = data
      })
  } catch (err) {
    console.log(err)
  } finally {
    isLoading.value = false
  }
}

async function downloadFile(file) {
  downloadFileWithLinkData(file, file.name)
}

onMounted(() => {
  console.log(route.params.id, '===')
  getProjectPoint()
})
</script>
