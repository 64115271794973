export const POINTS_STATUSES = {
  NEW: '9ab9e0f3-6408-4773-9785-f58c83fb6a85',
  FILLING: '3ab9194d-6eaf-4384-a667-b68fb2101bae',
  PROCESSING: '5ff9194d-6eaf-4384-a667-b68fb21ae888',
  NEEDS_IMPROVEMENTS: 'e971abb1-7e69-4b26-abf2-d0c105111ff5',
  NO_ACTION_REQUIRED: '1c4b4753-5463-46a8-9524-0b58aeaba48f',
  PROCESSING_REQUIRED: 'd9deb30e-6a64-42b1-a7b2-a5fa1aed79a1',
  TRANSFERRED_TO_MANAGER: '90d6595f-d45d-4bfd-b21d-c7aba84f72cb',
  FINALLY: '0e324ccb-ae49-4286-914d-9b63a4e486de',
  SUSPENDED: '4c8ccebc-bf56-46ff-b76e-efc7760d2f98',
  SIGNATURE_REQUIRED: '16534e9c-2dea-41a4-9df0-26ebe750f6ae',
  REJECTED: 'c6b64293-783b-4f97-95b0-2f3406f0e22b',
}

export const STATUSES = [
  {
    id: POINTS_STATUSES.NEW,
    label: 'Новый',
  },
  {
    id: POINTS_STATUSES.FINALLY,
    label: 'Завершен',
  },
  {
    id: POINTS_STATUSES.FILLING,
    label: 'На заполнении',
  },
  {
    id: POINTS_STATUSES.NEEDS_IMPROVEMENTS,
    label: 'Требуются доработки',
  },
  {
    id: POINTS_STATUSES.NO_ACTION_REQUIRED,
    label: 'Действий не требуется',
  },
  {
    id: POINTS_STATUSES.TRANSFERRED_TO_MANAGER,
    label: 'Передано менеджеру',
  },
  {
    id: POINTS_STATUSES.PROCESSING,
    label: 'Передано в обработку',
  },
  {
    id: POINTS_STATUSES.PROCESSING_REQUIRED,
    label: 'Требуется обработка',
  },
  {
    id: POINTS_STATUSES.SIGNATURE_REQUIRED,
    label: 'Требуется подпись',
  },
  {
    id: POINTS_STATUSES.REJECTED,
    label: 'Отклонен',
  },
  {
    id: POINTS_STATUSES.SUSPENDED,
    label: 'Приостановлено',
  },
]

export const POINT_TYPE_EXPERT = 'expert'
