<template>
  <v-dialog v-model="dialog" content-class="custom-dialog" transition="slide-x-transition">
    <v-card :width="sm || xs ? '100%' : 500" class="absolute overflow-hidden" height="100%">
      <v-card-title class="font-weight-bold px-6">Обратная связь</v-card-title>
      <v-card-text>
        <v-row v-if="loading" class="justify-center align-center py-6">
          <v-progress-circular color="primary" :indeterminate="true" />
        </v-row>
        <v-form v-else ref="form" v-model="validForm">
          <div v-for="field in fieldsForm" :key="field.id">
            <p v-if="field.title" class="field-name pa-0 ma-0 mb-2 text-grey">
              {{ field.title }}
            </p>
            <Component
              :is="inputs[getFormInput(field.type)]"
              :menu-props="{ closeOnClick: true, closeOnContentClick: true }"
              v-bind="field"
              @update-value="updateValue"
            />
          </div>
        </v-form>
      </v-card-text>
      <v-card-actions class="justify-center pa-4">
        <v-btn color="primary" class="flex-grow-1" variant="text" @click="dialogToggle">
          Закрыть
        </v-btn>
        <v-btn color="primary" class="flex-grow-1" variant="elevated" @click="submitForm">
          Отправить
        </v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script setup>
import { onMounted, ref } from 'vue'
import { api } from '@/api/Api'
import { v4 as uuidv4 } from 'uuid'
import FormText from '@/components/Form/FormText'
import FormTextarea from '@/components/Form/FormTextarea'
import FormSelect from '@/components/Form/FormSelect'
import FormFile from '@/components/Form/FormFile'
import { useToast } from '@/composables/useToast'
import { DEFAULT_ID_PROJECT } from '@/constants'
import { useDisplay } from 'vuetify'

const inputs = {
  FormText,
  FormTextarea,
  FormSelect,
  FormFile,
}

const { sm, xs } = useDisplay()

const dialog = ref(false)
const categories = ref([])
const validForm = ref(true)
const form = ref(null)
const loading = ref(false)
const { toast } = useToast()

const fieldsForm = ref([
  {
    id: uuidv4(),
    group_id: 0,
    type: 'select',
    name: 'theme',
    title: 'Категория обращения',
    options: [],
    default_value: '',
    descr: '',
    note: '',
    multiple: 0,
    rules: [['required']],
    sort: 1,
    itemValue: 'id',
    itemTitle: 'name',
  },
  {
    isVisible: true,
    id: uuidv4(),
    group_id: 0,
    type: 'text',
    name: 'subject',
    title: 'Тема обращения',
    default_value: '',
    descr: '',
    note: '',
    multiple: 0,
    rules: [['required'], ['string', { max: '255' }]],
    sort: 20,
  },
  {
    isVisible: true,
    id: uuidv4(),
    group_id: 0,
    type: 'textarea',
    name: 'message',
    title: 'Текст обращения',
    options: [],
    default_value: '',
    descr: '',
    note: '',
    multiple: 0,
    rules: [['required']],
    sort: 30,
  },
  {
    id: 5,
    group_id: 0,
    type: 'file',
    name: 'attachments',
    title: 'Вложения',
    options: [],
    default_value: [],
    descr: '',
    note: '',
    multiple: true,
    rules: [['required']],
    sort: 500,
  },
])

function dialogToggle() {
  dialog.value = !dialog.value
}

function getFormInput(type) {
  switch (type) {
    case 'text': {
      return 'FormText'
    }
    case 'textarea': {
      return 'FormTextarea'
    }
    case 'select': {
      return 'FormSelect'
    }
    case 'file': {
      return 'FormFile'
    }
    default: {
      return ''
    }
  }
}

function focusErrorInput({ id }) {
  const inputId = '#' + id
  document.querySelector(inputId).focus()
}

async function submitForm() {
  await form.value.validate()

  if (!validForm.value) {
    const errInput = form.value.errors[0]
    focusErrorInput(errInput)
    return
  }
  loading.value = true

  const formData = new FormData()
  formData.append('project', DEFAULT_ID_PROJECT)
  fieldsForm.value.map((el) => {
    if (el.name !== 'attachments') {
      formData.append(el.name, el.default_value)

      return formData
    } else {
      el.default_value.forEach((el) => {
        formData.append('attachments[]', el)
      })
    }
  })

  api
    .feedback()
    .sendFeedbackForm(formData)
    .then(() => {
      dialogToggle()
      toast.success('Сообщение отправлено')
      fieldsForm.value.forEach((el) => {
        el.default_value = null
      })
    })
    .catch((err) => {
      toast.error(err.message)
    })
    .finally(() => {
      loading.value = false
    })
}

function updateValue({ value, id }) {
  fieldsForm.value.forEach((item) => {
    if (item.id === id) {
      item.default_value = value
    }
  })
}

async function getCategories() {
  loading.value = true

  await api
    .feedback()
    .getListCategoriesProject(DEFAULT_ID_PROJECT)
    .then((data) => {
      categories.value = data?.items
      fieldsForm.value.find((field) => field.name === 'theme').options = data?.items
    })
    .finally(() => {
      loading.value = false
    })
}

defineExpose({
  dialogToggle,
})

onMounted(() => {
  getCategories()
})
</script>
<style lang="scss" scoped>
.title {
  font-size: 20px;
}

.field-name {
  font-size: 12px;
  line-height: 16px;
  margin-bottom: 4px;
}
</style>
