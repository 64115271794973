<template>
  <TasksFilterPanel
    v-if="showFilters"
    @name-change="handleNameChange"
    @type-change="handleTypeChange"
    @point-type-change="handleTypePointsChange"
  />
  <v-row v-if="isLoading" class="my-12 py-12 h-100 w-100 flex-grow-1 justify-center align-center">
    <v-progress-circular indeterminate color="primary" :size="50" class="ml-4" :width="3" />
  </v-row>
  <template v-else-if="tasks.length">
    <div class="tasks-page">
      <div class="tasks-list">
        <div v-if="showFilters && !isNotes" class="item head">
          <div>Задача</div>
          <div>Проект</div>
          <div>Статус</div>
          <div></div>
        </div>
        <div
          v-for="task in tasks"
          :key="task.id"
          :class="['item', { notes: isNotes }]"
          @click="() => goToTask(task)"
        >
          <div class="name">
            <div>
              {{ getPointName(task) }}
            </div>
            <div v-if="!isNotes" class="text-caption text-grey">ID: {{ task.id }}</div>
          </div>
          <div class="project-name">
            <v-tooltip
              v-if="task?.templateFullName"
              :activator="`#task-${task?.id}`"
              max-width="600"
              location="top"
            >
              <div>{{ removeHTMLTags(task?.templateFullName) }}</div>
            </v-tooltip>
            <div :id="`task-${task.id}`" class="tooltip-title" v-html="task?.templateName" />
            <div
              v-if="!isNotes && getProjectStatusName(task.projectId)"
              class="text-caption text-grey"
            >
              Статус проекта: {{ getProjectStatusName(task.projectId) }}
            </div>
            <div
              v-if="task.startDate || task.deadline"
              :class="[getStatusColor(task), 'ma-0 pa-0 text-caption']"
            >
              {{ getFormatDateTime(task.startDate) }}
              <template v-if="getFormatDateTime(task.deadline) && getFormatDateTime(task.startDate)"
                >-</template
              >
              {{ getFormatDateTime(task.deadline) }}
            </div>
          </div>
          <v-tooltip :text="task.status?.name" location="bottom">
            <template #activator="{ props }">
              <div v-bind="props" class="status-wrapper">
                <span :class="['status', getColorStatus(task.status)]">{{
                  task.status?.name
                }}</span>
              </div>
            </template>
          </v-tooltip>
          <v-tooltip :activator="`#task-go-${task.id}`" location="bottom">
            {{ task.canEdit ? 'Редактировать' : 'Перейти' }}
          </v-tooltip>
          <div :id="`task-go-${task.id}`" class="link"><ArrowRight /></div>
        </div>
      </div>
    </div>
  </template>
  <v-row v-else class="pa-12 text-grey ma-0 text-center justify-center align-center"
    >Список пуст</v-row
  >
</template>
<script setup>
import { computed, onMounted, ref, watch } from 'vue'
import { api } from '@/api/Api'
import { useRoute, useRouter } from 'vue-router'
import ArrowRight from '@/components/Icons/ArrowRight.vue'
import { POINTS_STATUSES } from '@/constants/points'
import { useToast } from '@/composables/useToast'
import TasksFilterPanel from '@/components/Tasks/TasksFilterPanel.vue'
import { getFormatDateTime } from '@/helpers'
import moment from 'moment/moment'

const props = defineProps({
  type: {
    type: String,
    default: '',
  },
  showFilters: {
    type: Boolean,
    default: false,
  },
  projects: {
    type: Array,
  },
  templateCode: {
    type: String,
    default: '',
    required: false,
  },
})

const route = useRoute()
const router = useRouter()
const { toast } = useToast()

const isNotes = computed(() => pointType.value === 'notes')
const tasks = ref([])
const isLoading = ref(false)
const name = ref()
const type = ref()
const pointType = ref()

const handleNameChange = (value) => {
  name.value = value
}

const handleTypePointsChange = (value) => {
  pointType.value = value
}

const handleTypeChange = (value) => {
  type.value = value
}
const getColorStatus = (status) => {
  if (!status || !status.code1C) {
    return ''
  }
  switch (status.code1C) {
    case POINTS_STATUSES.NEW:
      return 'green'
    case POINTS_STATUSES.SUSPENDED:
      return 'gray-2'
    case POINTS_STATUSES.FILLING:
      return 'blue'
    case POINTS_STATUSES.FINALLY:
      return 'gray-7'
    case POINTS_STATUSES.PROCESSING:
      return 'orange'
    case POINTS_STATUSES.NEEDS_IMPROVEMENTS:
      return 'yellow'
    case POINTS_STATUSES.NO_ACTION_REQUIRED:
      return 'gray-4'
    case POINTS_STATUSES.PROCESSING_REQUIRED:
      return 'violet'
    case POINTS_STATUSES.SIGNATURE_REQUIRED:
      return 'orange'
    case POINTS_STATUSES.TRANSFERRED_TO_MANAGER:
      return 'gray-1'
    default:
      return 'black'
  }
}

const getStatusColor = (point) => {
  const date = new Date()
  if (
    !point.startDate ||
    !point.deadline ||
    moment(date).isBefore(point.startDate) ||
    point.status.sort >= 1000
  ) {
    return 'text-grey'
  }

  const deadline = moment(point.deadline)
  const currentDate = moment()

  const difference = deadline.diff(currentDate, 'hours')

  if (difference <= 24 && difference > 0) {
    return 'text-orange'
  }

  if (moment(date).isAfter(point.startDate) && moment(date).isBefore(point.deadline)) {
    return 'text-green'
  }

  if (moment(date).isAfter(point.deadline) && point.status.sort < 1000) {
    return 'text-red'
  }

  if (moment(date).isAfter(point.startDate) && moment(date).isBefore(point.deadline)) {
    return 'text-green'
  }

  return 'text-grey'
}

const getPointName = (point) => {
  if (isNotes.value) {
    return `Черновик "${point.pointId ? point.pointName : point.name && `(${point.name})`}"`
  }

  return point.name || ''
}

const getProjectStatusName = (projectId) => {
  return props.projects?.find((el) => el.id === projectId)?.status?.name || ''
}

const goToTask = (task) => {
  if (isNotes.value) {
    const route = task.pointId ? `/tasks/${task.pointId}` : `/programs/create/${task.code1C}`
    return router.push(route)
  }
  return router.push(`/tasks/${task.id}`)
}

const removeHTMLTags = (str) => {
  return (str || '').replace(/<\/?[^>]+(>|$)/g, '')
}

async function getNotes() {
  isLoading.value = true
  try {
    await api
      .questionnaires()
      .getApplicationNotes()
      .then((data) => {
        tasks.value = data
      })
  } catch (err) {
    console.log(err)
  } finally {
    isLoading.value = false
  }
}
async function getTasks() {
  isLoading.value = true
  const params = {}
  if (route.params.id) {
    params['filter[templateCode]'] =
      route.name === 'project-by-id' ? props.templateCode : route.params.id
  }

  if (name.value) {
    params['filter[name]'] = name.value
  }

  if (type.value) {
    params['filter[statusCode]'] = type.value
  }
  if (isNotes.value) {
    return getNotes()
  }
  try {
    await api
      .projects()
      .getPoints(props.type || pointType.value, params)
      .then((data) => {
        tasks.value = data
      })
  } catch (err) {
    tasks.value = []
    if (err?.response?.data?.message) {
      toast.error(err?.response?.data?.message)
    }
    console.log(err)
  } finally {
    isLoading.value = false
  }
}

watch([name, type, pointType], () => {
  getTasks()
})

watch(props, getTasks)

onMounted(getTasks)
</script>
<style lang="scss" scoped>
.tasks-page {
  padding: 0 24px 24px 24px;
  border-bottom: 1px solid rgba(242, 242, 242, 1);

  .tasks-list {
    display: flex;
    flex-direction: column;
    gap: 8px;

    .item {
      display: grid;
      grid-template-columns: 1fr 1fr 1fr 24px;
      align-items: center;
      gap: 2rem;
      padding: 16px;
      background: var(--menu-color);
      border-radius: 8px;
      font-size: 14px;
      line-height: 20px;
      cursor: pointer;
      transition: all 0.3s;

      &.head {
        color: var(--text-caption);
        background: none;
        font-size: 12px;
        line-height: 16px;
        font-weight: 400;
        padding: 8px 16px;

        @media (max-width: 567px) {
          display: none;
        }
      }

      &:hover {
        background: var(--menu-light-color);
      }

      @media (max-width: 765px) {
        grid-template-areas:
          'a c'
          'b d';
        grid-template-columns: 2fr 1fr;

        .link,
        .status-wrapper {
          display: flex;
          justify-content: flex-end;
        }
      }

      @media (max-width: 400px) {
        display: flex;
        flex-direction: column;
        justify-content: flex-start;
        align-items: flex-start;
        gap: 4px;
        position: relative;
        .link {
          position: absolute;
          right: 20px;
          top: 20px;
        }
      }

      .link {
        cursor: pointer;
        transition: all 0.3s;

        @media (max-width: 765px) {
          grid-area: d;
        }

        &:hover {
          opacity: 0.8;
        }
      }

      .status-wrapper {
        @media (max-width: 765px) {
          grid-area: c;
        }

        .status {
          font-size: 12px;
          line-height: 16px;
          padding: 4px 12px;
          border-radius: 4px;
          text-align: center;
          display: inline-block;
          width: auto;
          color: white;
          white-space: nowrap;
          overflow: hidden;
          text-overflow: ellipsis;
          max-width: 170px;
        }
      }

      .project-name {
        font-size: 12px;
        line-height: 16px;
        color: var(--text-caption);

        @media (max-width: 765px) {
          grid-area: b;
        }
      }

      .name {
        @media (max-width: 765px) {
          grid-area: a;
        }
      }
    }
  }
}

.project-name {
  .tooltip-title {
    display: -webkit-box;
    -webkit-line-clamp: 3;
    -webkit-box-orient: vertical;
    overflow: hidden;
  }
}
</style>
