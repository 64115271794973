<template>
  <div :id="`inputCustom${id}`" class="radio">
    <v-radio-group
      :model-value="inputModel"
      :hint="descr"
      :persistent-hint="Boolean(descr)"
      :rules="rulesCommon"
      :readonly="readOnly"
      :error-messages="errorMessages"
      variant="outlined"
    >
      <v-radio
        v-for="radio in options"
        :key="radio.value"
        :value="radio.value"
        :read-only="readOnly"
        @change="!readOnly ? setValue(radio.value) : null"
      >
        <template #label>
          <span>{{ radio.name }}</span>
        </template>
      </v-radio>
    </v-radio-group>
  </div>
</template>

<script setup>
import { props as baseProps, emit as baseEmit } from '@/composables/form/usePropsForm'
import { useForm } from '@/composables/form/useForm'

const props = defineProps({
  ...baseProps,
})
const emit = defineEmits(baseEmit)

function setValue(value) {
  inputModel.value = value
  emit('change-value', { id: props.id, value: value })
}

const { rulesCommon, inputModel } = useForm(props, emit)
</script>
