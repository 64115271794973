<template>
  <svg width="48" height="48" viewBox="0 0 48 48" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      opacity="0.1"
      d="M30.2117 47.1821C34.2557 46.0985 37.946 43.9758 40.9159 41.0249C43.8859 38.074 46.0322 34.3975 47.1418 30.3605C48.2514 26.3235 48.2856 22.0665 47.2411 18.0121C46.1966 13.9578 44.1096 10.2473 41.1875 7.249C38.2654 4.25071 34.6098 2.06899 30.5837 0.920501C26.5576 -0.22799 22.3011 -0.303294 18.2369 0.702067C14.1727 1.70743 10.4421 3.75848 7.4158 6.65153C4.38946 9.54458 2.17257 13.179 0.985284 17.1938L3.89566 18.0545C4.93281 14.5474 6.86936 11.3725 9.513 8.84535C12.1566 6.31814 15.4154 4.52646 18.9657 3.64824C22.5159 2.77001 26.2341 2.83579 29.7511 3.83905C33.2681 4.8423 36.4614 6.74813 39.014 9.36726C41.5666 11.9864 43.3897 15.2277 44.3021 18.7693C45.2145 22.311 45.1846 26.0296 44.2154 29.5561C43.2461 33.0826 41.3712 36.2942 38.7768 38.8719C36.1824 41.4497 32.9588 43.3039 29.4262 44.2505L30.2117 47.1821Z"
      fill="url(#paint0_linear_4632_14632)"
    />
    <defs>
      <linearGradient
        id="paint0_linear_4632_14632"
        x1="13.8936"
        y1="5.24939"
        x2="-0.115034"
        y2="14.0853"
        gradientUnits="userSpaceOnUse"
      >
        <stop stop-color="#F37C4E" />
        <stop offset="1" stop-color="#FF4800" stop-opacity="0" />
      </linearGradient>
    </defs>
  </svg>
</template>
