<template>
  <div class="filters">
    <div class="input-wrapper">
      <SearchIcon />
      <input
        v-model="search"
        type="text"
        class="search"
        placeholder="Поиск по разделу"
        @change="handleChangeSearch"
      />
    </div>
    <v-select
      v-model="type"
      variant="outlined"
      :items="templatesOptions"
      placeholder="Все статусы"
      item-title="name"
      item-value="name"
      class="select"
      clearable
    />
    <v-checkbox v-model="hasActivePoints" class="checkbox" label="Есть задачи" />
    <v-checkbox v-model="hasUserActivePoints" class="checkbox" label="Я участвую" />
  </div>
  <v-row v-if="isLoading" class="my-12 py-12 w-100 justify-center align-center">
    <v-progress-circular indeterminate color="primary" :size="50" class="ml-4" :width="3" />
  </v-row>
  <div v-else class="list">
    <div v-for="template in templates" :key="template.id" class="ma-0 pa-0 list__item">
      <div>
        <div class="card-title">{{ template.name }}</div>
        <RouterLink :to="`/programs/${template.id || template.code1C}`"
          >Подробнее <ArrowRight
        /></RouterLink>
      </div>
      <div>
        <v-row class="pa-0 ma-0 justify-space-between align-center task-row">
          <div class="chips">
            <v-tooltip v-if="template.status" :text="template.status" location="bottom">
              <template #activator="{ props }">
                <div v-bind="props" class="chip chip_black">
                  {{ template.status }}
                </div>
              </template>
            </v-tooltip>

            <div v-if="template.hasUserActivePoints" class="chip chip_border">Участвую</div>
          </div>
          <div v-if="template.hasActivePoints" class="task">Есть задачи!</div>
        </v-row>
        <v-col class="pa-0 ma-0 flex-grow-1 font-weight-bold"> </v-col>
        <v-row class="pa-0 ma-0 d-flex align-end">
          <v-row class="pa-0 mx-0 flex-grow-1 w-100 dates flex-nowrap">
            <div v-if="template.dateFrom && template.dateTo">
              <div class="label">Проведение конкурса:</div>
              <div class="value">
                {{ getFormatDate(template.dateFrom) }} - {{ getFormatDate(template.dateTo) }}
              </div>
            </div>
            <div v-if="template.resultDateFrom && template.resultDateTo">
              <div class="label">Подведение итогов:</div>
              <div class="value">
                {{ getFormatDate(template.resultDateFrom) }} -
                {{ getFormatDate(template.resultDateTo) }}
              </div>
            </div>
          </v-row>
        </v-row>
      </div>
    </div>
    <div
      v-if="!templates.length"
      class="text-caption text-grey text-center w-100 py-6 flex-grow-1 d-flex justify-center align-center"
    >
      Нет данных
    </div>
  </div>
</template>
<script setup>
import { onMounted, ref, watch } from 'vue'
import { api } from '@/api/Api'
import moment from 'moment'
import store from '@/store'
import ArrowRight from '@/components/Icons/ArrowRight.vue'
import SearchIcon from '@/components/Icons/SearchIcon.vue'
import { useDisplay } from 'vuetify'

const { sm, xs } = useDisplay()

const isLoading = ref(false)
const templates = ref([])
const search = ref('')
const hasActivePoints = ref(false)
const hasUserActivePoints = ref(false)
const type = ref()
const templatesOptions = ref([
  {
    id: 1,
    name: 'Прием заявок',
  },
])

async function getTemplates() {
  try {
    isLoading.value = true
    const params = {}
    if (type.value) {
      params['filter[status]'] = type.value
    }

    if (search.value) {
      params['filter[name]'] = search.value
    }

    if (hasUserActivePoints.value) {
      params['filter[hasUserActivePoints]'] = +hasUserActivePoints.value
    }

    if (hasActivePoints.value) {
      params['filter[hasActivePoints]'] = +hasActivePoints.value
    }

    await api
      .programs()
      .getActiveTemplates(params)
      .then((data) => (templates.value = data))
  } catch (err) {
    console.log(err)
  } finally {
    isLoading.value = false
  }
}

const handleChangeSearch = () => {
  getTemplates()
}

function getFormatDate(date) {
  return moment(date).format('DD.MM.YYYY')
}

const setPageTitle = () => {
  if (xs.value || sm.value) {
    return store.commit('app/setPageTitle', 'Конкурсы')
  }
  return store.commit('app/setPageTitle', 'Текущие конкурсы')
}

watch([type, hasActivePoints, hasUserActivePoints], () => {
  getTemplates()
})

onMounted(() => {
  setPageTitle()
  getTemplates()
})
</script>
<style lang="scss" scoped>
.list {
  display: flex;
  flex-direction: column;
  gap: 8px;
  flex: 1;
  padding: 24px;

  &__item {
    background: var(--bage-color);
    display: grid;
    grid-template-columns: 1fr 380px;
    align-items: stretch;
    border-radius: 8px;

    a {
      font-size: 12px;
      line-height: 16px;
    }

    @media (max-width: 765px) {
      grid-template-columns: 1fr;
    }

    > div {
      padding: 24px;
      display: flex;
      flex-direction: column;

      &:first-child {
        border-right: 2px solid white;
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        gap: 16px;

        @media (max-width: 765px) {
          padding-bottom: 0;
        }
      }

      a {
        display: flex;
        align-items: center;
        gap: 8px;
      }

      .dates {
        gap: 16px;
        margin-top: 16px;

        @media (max-width: 765px) {
          flex-direction: column;
          gap: 8px;
        }

        .label {
          color: var(--text-caption);
          font-size: 10px;
        }

        .value {
          font-size: 14px;
          line-height: 20px;
        }
      }
    }
  }
}

.filters {
  padding: 12px 24px;
  border-bottom: 1px solid rgba(242, 242, 242, 1);

  .checkbox {
    margin-bottom: 0;
    max-width: 136px;

    .v-label {
      font-size: 14px;
    }
  }
}

.card-title {
  a {
    svg {
      color: var(--primary);
    }
  }
}
</style>

<style lang="scss">
.filters {
  display: flex;
  flex-wrap: nowrap;
  align-items: center;
  gap: 24px;

  @media (max-width: 765px) {
    display: grid;
    grid-template-columns: 1fr 1fr;
    gap: 10px;
  }

  @media (max-width: 567px) {
    display: grid;
    grid-template-columns: 1fr;
    gap: 10px;
  }

  .input-wrapper {
    background: var(--bage-color);
    border: 1px solid var(--bage-color);
    display: flex;
    align-items: center;
    gap: 10px;
    padding: 12px;
    height: 40px;
    border-radius: 8px;
    flex: 1;

    input {
      height: 100%;
      min-height: 40px;
      display: block;
      flex: 1;
      font-size: 14px;
    }
  }

  .v-input--density-default {
    --v-input-control-height: 40px;
    --v-input-padding-top: 12px;
  }

  .v-input__details {
    display: none;
  }

  .checkbox {
    .v-icon--size-default {
      font-size: 24px;
    }
  }

  .select {
    max-width: 194px;
    width: 100%;

    @media (max-width: 765px) {
      max-width: 100%;
    }

    .v-field {
      border-radius: 8px;
    }
    .v-field__input {
      padding: 10px 12px;
      overflow: hidden;

      span {
        font-size: 14px;
        line-height: 20px;
        white-space: pre;
      }
    }
  }
}
</style>
