<template>
  <v-dialog :key="result" v-model="dialog" class="dialog" persistent>
    <v-card elevation="0" :class="['card-dialog', parseLoad ? 'load' : 'has-data']" flat>
      <div :class="['d-flex justify-space-between align-center head', parseLoad && 'silver']">
        <div class="title">{{ parseFile?.title }}</div>
        <div class="name">{{ fileName }}</div>
      </div>
      <div :class="['content', parseLoad && 'silver']">
        <div v-if="parseLoad" class="parsing d-flex flex-column justify-center align-center">
          <div class="spinner-wrapper">
            <ParsingSpinner />
          </div>
          <div class="title">Распознавание документа</div>
          <div class="subtitle">Это займет не более 15 секунду, немного терпения...</div>
        </div>
        <template v-else-if="formatData">
          <v-alert
            v-if="hasError"
            text="Исправьте, пожалуйста, ошибки в загружаемом файле и повторите загрузку"
            type="info"
            variant="tonal"
          />
          <v-row v-if="isLoading" class="px-0 justify-center align-center">
            <v-progress-circular color="primary" :indeterminate="true" />
          </v-row>
          <div
            v-for="(group, groupIndex) in Object.keys(groups)"
            :key="`group-${group}-${groupIndex}`"
          >
            <div v-if="group">
              <div class="mb-4 text-body-1 ma-0 mt-4 text-subtitle-1 font-weight-bold text-grey">
                {{ getGroupName(group) }}
              </div>
              <div class="table-wrapper">
                <v-table v-if="getTableData(groups[group])?.length > 1" class="w-100">
                  <thead>
                    <tr>
                      <th
                        v-for="(th, thIndex) in getThTable(groups[group])"
                        :key="`${th}-${thIndex}`"
                      >
                        {{ th }}
                      </th>
                    </tr>
                  </thead>
                  <tbody class="w-100">
                    <tr v-for="(tr, trIndex) in getTableData(groups[group])" :key="trIndex">
                      <td v-for="(td, tdIndex) in tr" :key="tdIndex">
                        <div v-if="td.value">{{ td.value }}</div>
                        <div v-if="td.error" class="text-red font-weight-black">
                          {{ td.error }}
                        </div>
                      </td>
                    </tr>
                  </tbody>
                </v-table>
                <div v-else>
                  <v-row
                    v-for="(field, index) in getThTable(groups[group])"
                    :key="`${field}-${index}`"
                    :class="[
                      'align-start pa-0 ma-0 py-2',
                      getThTable(groups[group])?.length !== index + 1 && 'border-bottom-line',
                    ]"
                  >
                    <v-col cols="4" class="pa-0 ma-0 font-weight-bold">{{ field }}</v-col>
                    <v-col cols="8" class="pa-0 ma-0 pl-2">
                      <div>
                        {{ groups[group][index]?.values[0].value }}
                      </div>
                      <div
                        v-if="groups[group][index]?.values[0].error"
                        class="text-red font-weight-black"
                      >
                        {{ groups[group][index]?.values[0].error }}
                      </div>
                    </v-col>
                  </v-row>
                </div>
              </div>
            </div>
            <div v-else class="grid-table-wrapper">
              <div v-for="groupField in groups[group]" :key="groupField.code1C">
                <div class="d-flex row-td-table flex-nowrap border-bottom-line">
                  <v-col cols="4" class="pa-0 ma-0 row-td-table__title">{{
                    groupField.name
                  }}</v-col>
                  <v-col cols="8" class="pa-0 ma-0 row-td-table__value">
                    <div v-for="item in groupField?.values" :key="item.value">
                      <div>{{ getValue(item) || '' }}</div>
                      <div v-if="item.error" class="text-red font-weight-black">
                        {{ item.error }}
                      </div>
                    </div>
                  </v-col>
                </div>
              </div>
            </div>
          </div>
        </template>
      </div>
      <div class="footer d-flex justify-end align-center">
        <v-btn v-if="parseLoad" class="default-button" style="color: #2f2c3e" @click="resetForm"
          >Отменить загрузку</v-btn
        >
        <template v-else>
          <template v-if="!hasError">
            <v-btn
              style="color: #2f2c3e; background: #f2f2f2"
              variant="flat"
              @click="declineParseFile"
            >
              Отменить загрузку
            </v-btn>
            <v-btn color="primary" variant="flat" @click="acceptParseFile">
              Принять и добавить
            </v-btn>
          </template>
          <v-btn v-else variant="flat" @click="resetForm"> Продолжить редактирование </v-btn>
        </template>
      </div>
    </v-card>
  </v-dialog>
</template>

<script setup>
import { computed, onMounted, onUpdated, ref } from 'vue'
import { api } from '@/api/Api'
import { emit as baseEmit } from '@/composables/form/usePropsForm'
import ParsingSpinner from '@/components/ParsingSpinner.vue'
import { useRoute } from 'vue-router'

const route = useRoute()

const dialog = ref(false)
const isLoading = ref(false)
const formatData = ref({})
const hasError = ref(false)

const fields = ref([])
const groupsFields = ref([])
const groups = ref({})

const emit = defineEmits(baseEmit)
const props = defineProps({
  title: {
    type: String,
    default: '',
  },
  parseLoad: {
    type: Boolean,
    default: false,
  },
  attachmentCode: {
    type: String,
    default: '',
  },
  fileIndex: {
    type: Number,
    default: null,
  },
  result: {
    type: Object,
    default: () => {},
  },
  point: {
    type: Object,
    default: () => {},
  },
  parseFile: {
    type: Object,
    default: () => {},
  },
})

const getGroupName = (codeId) => {
  return groupsFields.value?.find((group) => group.code1C === codeId)?.name || ''
}

const getThTable = (group) => {
  return group ? Object.values(group).map((el) => el.name) : []
}

const getValue = (val) => {
  if (val.error) {
    hasError.value = true
  }

  return val.value
}

const fileName = computed(() => `${props.parseFile?.file?.name || ''}`)

const getTableData = (group) => {
  const table = []
  if (!group.length) {
    return []
  }
  group.forEach((col, colIndex) => {
    col.values.forEach((val, valIndex) => {
      if (val.error) {
        hasError.value = true
      }
      if (table.length < valIndex + 1) {
        table.push([])
      }
      if (table.length >= valIndex + 1 && table[valIndex].length >= colIndex) {
        table[valIndex][colIndex] = val
      }
    })
  })

  return table
}

function dialogToggle() {
  dialog.value = !dialog.value
}
function closeDialog() {
  hasError.value = false
  dialog.value = false
}

function resetForm() {
  declineParseFile()
  closeDialog()
}

async function acceptParseFile() {
  isLoading.value = true
  try {
    const params = new FormData()
    params.append('attachementCode', props.attachmentCode)
    params.append('fileIndex', props.fileIndex)
    if (route.params?.id) {
      params.append('pointId', params.params?.id)
    }
    api
      .questionnaires()
      .acceptParseFile(params)
      .then(() => {
        emit('accept', props.attachmentCode, props.fileIndex || 0)
        closeDialog()
      })
  } catch (err) {
    console.log(err)
  } finally {
    isLoading.value = false
  }
}

async function declineParseFile() {
  isLoading.value = true
  try {
    const params = new FormData()
    params.append('attachementCode', props.attachmentCode)
    params.append('fileIndex', props.fileIndex)
    if (route.params?.id) {
      params.append('pointId', params.params?.id)
    }
    api
      .questionnaires()
      .declineParseFile(params)
      .then(() => {
        emit('decline', props.attachmentCode, props.fileIndex, props)
        closeDialog()
      })
  } catch (err) {
    console.log(err)
  } finally {
    isLoading.value = false
  }
}

function getGroupsValuesById(arr, field) {
  return arr?.reduce((result, acc) => {
    result[acc[field] || ''] = result[acc[field] || ''] || []
    result[acc[field] || ''].push(acc)

    return result
  }, {})
}

defineExpose({
  dialogToggle,
})

function formatingData() {
  fields.value = props.point?.templateFields
  groupsFields.value = props.point?.templateGroups
  hasError.value = false
  if (!props.result) {
    return
  }
  groups.value = getGroupsValuesById(Object.values(props.result), 'groupCode')
}

onUpdated(formatingData)

onMounted(formatingData)
</script>
<style lang="scss">
.spinner-wrapper {
  margin-bottom: 40px;
}

.card-dialog {
  width: 100%;
  &.load {
    max-width: 980px;
  }

  &.has-data {
    max-width: 1390px;
  }
}
</style>
