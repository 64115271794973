import { axiosWinnerUser } from '@/plugins/axios'
import { axiosBase } from '@/plugins/axios'
import { POINTS_STATUSES } from '@/constants/points'

export class Projects {
  saveFieldInCache(data) {
    return axiosWinnerUser.post('/v1/stash', data).then((response) => response.data)
  }

  acceptProjectRequest(id) {
    return axiosWinnerUser.patch(`v1/projects/${id}/accept`).then((response) => response.data)
  }

  getProjects() {
    return axiosWinnerUser.get('/v1/projects').then((response) => response.data)
  }

  createProject(data) {
    return axiosWinnerUser.post('/v1/projects', data).then((response) => response.data)
  }

  updateProject(projectId, data) {
    return axiosWinnerUser.put(`v1/projects/${projectId}`, data).then((response) => response.data)
  }

  getProjectData(id) {
    return axiosWinnerUser.get(`v1/projects/${id}`).then((response) => response.data)
  }

  sendCommentsToFields(projectId, data) {
    return axiosWinnerUser.post(`v1/projects/${projectId}/comments`, data)
  }

  returnsProjectFormToWinnerForRevision(projectId, comment) {
    return axiosWinnerUser
      .patch(`v1/projects/${projectId}/reject`, { comment })
      .then((response) => response.data)
  }

  sendForManagerRequestWithConfirm(id, data) {
    return axiosWinnerUser.post(`v1/projects/${id}/review`, data)
  }

  getComments(projectId) {
    return axiosWinnerUser
      .get(`v1/projects/${projectId}/comments`)
      .then((response) => response.data)
  }

  managerSubmitFormToMonitor(id, comment) {
    return axiosWinnerUser
      .patch(`v1/projects/${id}/send-for-approval`, { comment })
      .then((response) => response.data)
  }

  getCenterNtiList() {
    return axiosWinnerUser.get('/v1/projects/center-nti').then((response) => response.data)
  }

  deleteCenterNti(id) {
    return axiosWinnerUser.delete(`/v1/projects/center-nti/${id}`).then((response) => response.data)
  }

  createCenterNti(fullTitle, shortTitle, programNumber, codePpp) {
    return axiosWinnerUser
      .post('/v1/projects/center-nti', { fullTitle, shortTitle, programNumber, codePpp })
      .then((response) => response.data)
  }

  uploadIndicatorParserSummary(projectId, data) {
    return axiosWinnerUser.post(`/v1/projects/${projectId}/indicator-parser/summary`, data)
  }

  uploadIndicatorParserYears(projectId, data) {
    return axiosWinnerUser.post(`/v1/projects/${projectId}/indicator-parser/years`, data)
  }

  uploadIndicatorParserPerformance(projectId, data) {
    return axiosWinnerUser.post(`/v1/projects/${projectId}/indicator-parser/performance`, data)
  }

  uploadIndicatorParserImplementationPlan(projectId, data) {
    return axiosWinnerUser.post(
      `/v1/projects/${projectId}/indicator-parser/implementation-plan`,
      data,
    )
  }

  uploadIndicatorParserAdditionalNeed(projectId, data) {
    return axiosWinnerUser.post(`/v1/projects/${projectId}/indicator-parser/additional-need`, data)
  }

  exportProjects() {
    return axiosWinnerUser
      .get('/v1/projects/export', { responseType: 'blob' })
      .then((response) => response.data)
  }

  exportAgreementsList() {
    return axiosWinnerUser
      .get('/v1/projects/export-document', { responseType: 'blob' })
      .then((response) => response.data)
  }

  exportPrograms() {
    return axiosWinnerUser
      .get('/v1/projects/export-program', { responseType: 'blob' })
      .then((response) => response.data)
  }

  downloadTemplateForParsing(type) {
    const params = new URLSearchParams({
      templateType: type,
    }).toString()

    return axiosWinnerUser
      .get(`/v1/projects/template?${params}`, { responseType: 'blob' })
      .then((response) => response.data)
  }

  // new
  getProjectsList() {
    return axiosBase.get('/v2/projects/project ').then((response) => response.data)
  }

  getActivePoints() {
    return axiosBase.get('/v2/projects/project-point/active').then((response) => response.data)
  }

  getAllPoints() {
    return axiosBase.get('/v2/projects/project-point').then((response) => response.data)
  }

  getPoints(type, params) {
    return axiosBase
      .get(`/v2/projects/project-point${type ? `/${type}` : ''}`, { params })
      .then((response) => response.data)
  }

  getExpiredPoints() {
    return axiosBase.get('/v2/projects/project-point/expired').then((response) => response.data)
  }

  getPointsCount(params) {
    return axiosBase
      .get('/v2/projects/project-point/count', { params })
      .then((response) => response.data)
  }

  getActivePointsCount(params) {
    return axiosBase
      .get('/v2/projects/project-point/active-count', { params })
      .then((response) => response.data)
  }

  getProjectBriefcaseName(code1C) {
    return axiosBase.get(`/v2/projects/briefcase/${code1C}`).then((response) => response.data)
  }

  getProjectProgrammName(code1C) {
    return axiosBase.get(`/v2/projects/programm/${code1C}`).then((response) => response.data)
  }

  getProjectProjectTypeName(code1C) {
    return axiosBase.get(`/v2/projects/project-type//${code1C}`).then((response) => response.data)
  }

  getSpentPoints() {
    const params = new URLSearchParams({
      'filter[active]': 0,
      'filter[visible]': 1,
      'filter[statusCode]': POINTS_STATUSES.FINALLY,
    }).toString()
    return axiosBase.get(`/v2/projects/project-point?${params}`).then((response) => response.data)
  }

  getClosedPoints() {
    return axiosBase.get(`/v2/projects/project-point/closed`).then((response) => response.data)
  }

  getPointStatuses() {
    return axiosBase.get('/v2/projects/point-status').then((response) => response.data)
  }

  getPointById(id) {
    return axiosBase.get(`/v2/projects/project-point/${id}`).then((response) => response.data)
  }

  savePoint(id, data) {
    return axiosBase
      .post(`/v2/projects/project-point/application/${id}`, data)
      .then((response) => response.data)
  }

  withdrawApp(id) {
    return axiosBase.post(`/v2/projects/project-point/hold/${id}`).then((response) => response.data)
  }
  updatePoint(id, data) {
    return axiosBase
      .put(`/v2/projects/project-point/application/${id}`, data)
      .then((response) => response.data)
  }

  getFileForSign(id, isBase64Format) {
    const query = isBase64Format ? '?txt=Y' : ''
    return axiosBase
      .get(`/v2/projects/project-point/pdf/${id}${query}`, { responseType: 'blob' })
      .then((response) => response.data)
  }

  getFileForSignString(id, isBase64Format) {
    const query = isBase64Format ? '?txt=Y' : ''
    return axiosBase
      .get(`/v2/projects/project-point/pdf/${id}${query}`)
      .then((response) => response.data)
  }

  signFile(id, data) {
    return axiosBase
      .post(`/v2/projects/project-point/sign/${id}`, data)
      .then((response) => response.data)
  }
}
