<template>
  <auth-layout>
    <div class="form">
      <div class="form__body">
        <header class="header">
          <SvgBase width="129" height="35">
            <SvgLogo />
          </SvgBase>
        </header>
        <p class="text-body-1 text-grey text-center">
          Для продолжения требуется <br />
          авторизоваться с помощью
          <SvgBase width="80" height="15" view-box="0 0 135 20">
            <SvgLogoLeaderId />
          </SvgBase>
        </p>

        <v-btn :block="true" color="primary" @click="login"> Войти </v-btn>
      </div>
    </div>
  </auth-layout>
</template>

<script setup>
import { api } from '@/api/Api'
import AuthLayout from '@/layouts/auth'
import SvgBase from '@/components/Svg/SvgBase'
import SvgLogoLeaderId from '@/components/Svg/SvgLogoLeaderId'
import SvgLogo from '@/components/Svg/SvgLogo.vue'

function login() {
  const uri = api.auth().authorize()
  window.open(uri, '_self')
}
</script>

<style lang="scss" scoped>
.form {
  width: 550px;
  display: flex;
  flex-direction: column;
  max-width: 1080px;
  height: 100%;
  justify-content: flex-start;
  align-items: flex-start;

  &__body {
    display: flex;
    box-shadow: 0 0 20px rgba(0, 0, 0, 0.08);
    align-items: center;
    justify-content: center;
    padding: 60px;
    flex-direction: column;
    height: 100%;
    gap: 14px;
    width: 100%;
    background: white;

    button {
      max-height: 56px;
      font-size: 18px;
      border-radius: 20px;
      text-transform: uppercase;
    }

    @media (max-width: 599.95px) {
      padding: 24px 16px 45px;
    }
  }

  @media (max-width: 599.95px) {
    width: 100%;
    padding-left: 16px;
    padding-right: 16px;
  }
}
</style>
