<template>
  <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 32 32">
    <g id="_93-List" data-name="93-List">
      <rect
        fill="none"
        stroke-width="2"
        class="cls-1"
        height="30"
        rx="2"
        ry="2"
        width="30"
        x="1"
        y="1"
      />
      <circle
        fill="none"
        stroke-width="2"
        stroke="currentColor"
        class="cls-1"
        cx="7"
        cy="16"
        r="1"
      />
      <circle
        fill="none"
        stroke-width="2"
        stroke="currentColor"
        class="cls-1"
        cx="7"
        cy="9"
        r="1"
      />
      <circle
        fill="none"
        stroke-width="2"
        stroke="currentColor"
        class="cls-1"
        cx="7"
        cy="23"
        r="1"
      />
      <line
        fill="none"
        stroke-width="2"
        stroke="currentColor"
        class="cls-1"
        x1="11"
        x2="27"
        y1="9"
        y2="9"
      />
      <line
        fill="none"
        stroke-width="2"
        stroke="currentColor"
        class="cls-1"
        x1="11"
        x2="27"
        y1="16"
        y2="16"
      />
      <line
        fill="none"
        stroke-width="2"
        stroke="currentColor"
        class="cls-1"
        x1="11"
        x2="27"
        y1="23"
        y2="23"
      />
    </g>
  </svg>
</template>
