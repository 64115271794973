<template>
  <v-app-bar elevation="0" class="pa-0 ma-auto">
    <div class="main-container d-flex align-center justify-space-between">
      <v-app-bar-nav-icon v-if="isMobile" @click.stop="handleClickDrawer" />
      <div class="head-title">{{ title || '' }}</div>
      <v-row class="px-0 mx-0 align-center justify-end flex-grow-1 flex-nowrap">
        <div>
          <the-notifications />
        </div>
        <v-row
          class="pa-0 ma-0 ml-4 align-center user-info flex-grow-0"
          @click="openUserInfoDialog"
        >
          <div class="user-preview">
            {{ userInitials }}
          </div>
          <div v-if="!isMobile" class="description">
            <div id="name" class="name">{{ userName }}</div>
            <v-tooltip :text="userName" location="bottom" activator="#name" />
            <div id="role" class="role">{{ userRole }}</div>
            <v-tooltip :text="userRole" location="bottom" activator="#role" />
          </div>
        </v-row>
        <div>
          <v-btn id="logout" icon="" color="primary" @click="logout">
            <v-tooltip v-if="userName || userRole" activator="#logout" location="bottom"
              >Выйти</v-tooltip
            >
            <LogoutIcon />
          </v-btn>
        </div>
      </v-row>
    </div>
  </v-app-bar>
  <UserInfo ref="userInfoDialog" />
</template>
<script setup>
import { computed, ref, watch } from 'vue'
import UserInfo from '@/components/User/UserInfo'
import TheNotifications from '@/components/TheNotifications'
import LogoutIcon from '@/components/Icons/LogoutIcon.vue'
import { useStore } from 'vuex'
import { useRouter } from 'vue-router'
import { useDisplay } from 'vuetify'

const store = useStore()
const router = useRouter()
const { xs, sm } = useDisplay()

defineProps({
  tabs: {
    type: Array,
    default: () => [],
  },
})

const emit = defineEmits(['drawer-click'])

const userInfoDialog = ref(null)

const drawer = ref(false)

const isMobile = computed(() => xs.value || sm.value)
const userName = computed(() => store.getters['user/userName'])
const userInitials = computed(() =>
  userName.value
    ?.split(' ')
    .map((word) => word.charAt(0))
    .join(''),
)
const userRole = computed(() => store.getters['user/userRoleInString'])
const title = computed(() => store.getters['app/pageTitle'])

const handleClickDrawer = () => {
  emit('drawer-click')
}

function logout() {
  localStorage.removeItem('access_token')
  router.push('/login')
}
function openUserInfoDialog() {
  if (userInfoDialog.value) {
    userInfoDialog.value.open()
  }
}

watch(isMobile, () => {
  drawer.value = !isMobile.value
})

watch(drawer, () => {
  store.commit('app/setDrawer', drawer.value)
})
</script>

<style lang="scss" scoped>
.main-container {
  width: 100%;
  padding: 18px 24px;
}

.logo {
  flex: 0 0 auto;
  max-width: 138px;
  margin-right: auto;
  line-height: normal;
}

.tabs {
  flex-grow: 1;
  margin-left: 36px;
}
.user {
  margin-left: 0;
  text-transform: inherit;
  &__name {
    display: block;
    max-width: 250px;
  }
}

.head-button {
  width: 20px;
  min-width: 20px;
}

.user-preview {
  background: var(--bage-color);
  color: var(--color-neutral-darkest);
  width: 32px;
  height: 32px;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 50%;
  margin-right: 8px;
  font-size: 12px;
  line-height: 16px;
  font-weight: bold;
  text-transform: uppercase;
}

.user-info {
  cursor: pointer;

  @media (max-width: 567px) {
    order: -1;
  }

  .description {
    > div {
      width: 150px;
      display: -webkit-box;
      -webkit-line-clamp: 1;
      -webkit-box-orient: vertical;
      overflow: hidden;

      &.name {
        font-size: 12px;
        line-height: 16px;
      }

      &.role {
        font-size: 10px;
        line-height: 16px;
        color: var(--text-caption);
      }
    }
  }
}

.head-title {
  font-size: 24px;
  line-height: 32px;
  font-weight: 700;

  @media (max-width: 567px) {
    font-size: 16px;
    line-height: 1.4;
  }
}
</style>
